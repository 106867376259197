@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:after {
  transition: opacity 0.3s ease;
  opacity: 0;
}
body.contribute-panel-on {
  overflow: hidden;
}
body.contribute-panel-on:after {
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  opacity: 1;
}

/* this is bad but we need to make our own */
*:active,
*:focus,
*:hover {
  outline: none !important;
}

/* Highlight */
/* Doesn't seem worth trying to do this the tailwind way */
.highlight {
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 50%, #ffdf7e 0);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
}

/* Highlight for homepage */
/* .highlight breaks on mobile so using this */
.yellow-highlight {
  display: inline;
  width: 100%;
  text-decoration: none;
  padding-bottom: 0px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(255, 255, 255, 0)),
    color-stop(50%, #fede84)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #fede84 50%);
}

.hover\:elevation-0 {
  transition: all 0.3s ease;
}

.hover\:elevation-0:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.hover\:elevation-1 {
  transition: all 0.3s ease;
}

.hover\:elevation-1:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
}

.hover\:elevation-2 {
  transition: all 0.3s ease;
}

.hover\:elevation-2:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
}

/* Details/Summary */
/* This will toggle the preceding text for any of these */
/* The items needs data-attributes */
/* data-open="Close" data-close="Show" */

details[open] summary::before {
  content: attr(data-open);
}

details:not([open]) summary::before {
  content: attr(data-close);
}

@media not all and (min-resolution: 0.001dpcm) {
  @media screen {
    details[open] summary::before {
      display: none;
    }

    details:not([open]) summary::before {
      display: none;
    }
  }
}

/* Longform */
/* This will be a collection of styles for markdown or rich text areas. Unsure how this would be done in tailwind */
.longform p:not(:first-child) {
  margin-top: 1rem;
}

@media screen and (min-width: 1200px) {
  .longform-lg p:not(:first-child) {
    margin-top: 2rem;
  }
}

.rec-item-wrapper {
  height: 100%;
}

.rec-slider-container {
  margin: 0 !important;
}

.bottom-fade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) -15.87%,
    #ffffff 73.52%
  );
}

.card {
  max-width: 356px;
  width: 100%;
}

.card-img-container {
  flex-shrink: 0;
  height: 154px;
  overflow: hidden;
  width: 132px;
}

.rounded-50pc {
  border-radius: 50%;
}

.max-w-xxs {
  max-width: 17rem;
}

@media screen and (min-width: 1024px) {
  /* This is fragile, but I'm unsure how else to do it. Subtracting the headers and taking the rest of the viewport */
  .timeline-wrapper {
    min-height: calc(100vh - 145px);
  }

  .card {
    max-width: 368px;
  }

  .bg-header {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  /* Circle overrides */
  .circle-widget-trigger {
    display: none !important;
  }
}

/* react-responsive-modal */

.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-modal.custom-signup-modal {
  width: 90%;
  padding: 0;
}

.custom-signup-modal-illustration {
  display: none;
}

@media screen and (min-width: 932px) {
  .react-responsive-modal-modal.custom-signup-modal {
    max-width: 900px;
    width: 900px;
  }
  .custom-signup-modal-content {
    display: flex;
    min-height: 460px;
  }
  .custom-signup-modal-info {
    width: 400px;
  }
  .custom-signup-modal-illustration {
    display: block;
    width: 500px;
  }
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

.modal-submit-tip {
  max-width: 640px;
  width: 100%;
}

/* Active timeline items */

.timeline-axis {
  left: 7px;
}

.timeline-circle {
  border-color: #6b7280; /* gray-500 */
}

.timeline-container::before {
  border-left: 3px dotted #6b7280; /* gray-500 */
  bottom: -64px;
  content: "";
  left: 6px;
  position: absolute;
  top: 6px;
  width: 1;
}

.timeline-item:hover .timeline-circle {
  background-color: #edaa9b; /* red-300 */
  border-color: #edaa9b; /* red-300 */
}

.timeline-active,
.timeline-item:hover .timeline-circle.timeline-active {
  background: #db5437; /* red */
  border-color: #db5437; /* red */
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

.subNavHeaderActive::before {
  background-color: #f3bb1c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  bottom: -1px;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .subNavHeaderActive::before {
    height: 8px;
    left: calc(50% - 84px);
    max-width: 167px;
  }
}

.cluster-marker {
  align-items: center;
  background: #000;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25), 0 0 0 8px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: flex;
  font-weight: 600;
  height: 25px;
  justify-content: center;
  width: 25px;
}

.pin-count {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}

.custom-workspace-text {
  display: none;
  bottom: 150px;
  position: absolute;
  right: 16px;
  z-index: 10;
}

.custom-workspace-text strong {
  font-size: 3.25rem;
}

@media screen and (min-width: 1150px) {
  .custom-workspace-text {
    display: block;
  }
}

button:disabled,
.react-select--is-disabled {
  opacity: 0.5;
}

@media screen and (min-width: 1024px) {
  main {
    margin-top: 56px;
  }
  main.bg-gray-100 {
    margin-top: 0;
  }
}

/* Tailwind additions */

.custom-max-w-40rem {
  max-width: 40rem;
}

.custom-grayscale {
  filter: grayscale(100%);
  transition: filter 0.3s linear;
}

.hover\:custom-grayscale-0:hover {
  filter: grayscale(0);
}

/* Longform */

.longform p:not(:first-of-type),
.longform ul,
.longform ol {
  margin-top: 1rem;
}

.longform ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.longform li {
  margin-top: 0.5rem;
}

.longform a {
  color: rgb(219, 84, 55);
  font-weight: 600;
}

.longform a:hover {
  color: rgb(185, 28, 28);
}

.longform h3 + p:not(:first-of-type) {
  margin-top: 0.25rem;
}

/* About */

.custom-about-height {
  height: 80vh;
}

@media screen and (min-width: 768px) {
  .custom-about-height {
    height: 600px;
  }
}

/* Media Gallery */
.video-wrapper {
  position: relative;
  padding: 28% 0;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.media-gallery-content {
  max-height: calc(100vh - 105px);
}

/* X Small */
@media screen and (min-width: 1px) {
  .video-wrapper {
    min-width: 240px;
    margin: 0 3rem;
  }
}

/* Small */
@media screen and (min-width: 640px) {
  .video-wrapper {
    min-width: 400px;
    margin: 0 4rem;
  }
}

/* Medium */
@media screen and (min-width: 768px) {
  .video-wrapper {
    min-width: 500px;
    margin: 0 5rem;
  }
}

/* Large */
@media screen and (min-width: 1024px) {
  .video-wrapper {
    min-width: 600px;
    margin: 0 5rem;
  }
}

/* Add this custom popup */

.custom-popup::after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid white;
  content: "";
  height: 0;
  left: calc(50% - 6px);
  position: absolute;
  top: calc(100% - 1px);
  width: 0;
}

/* added specific classname to the tab follow-case button to avoid tailwind conflicts */

/*@media screen and (min-width: 768px) {*/
/*  .follow-case-tab {*/
/*    display: none;*/
/*  }*/
/*}*/
@media screen and (max-width: 768px) {
  .follow-case-tab {
    border: 0 !important;
    max-width: 87px;
    color: #ffff;
  }
}

.select-custom {
  overflow: hidden;
  position: relative;
  border: 1px solid #e0e4e8;
}
.select-custom::after {
  content: "";
  border-left: 2px solid rgba(0, 0, 0, 0.25);
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  width: 8px;
  height: 8px;
  position: absolute;
  right: 10px;
  top: 14px;
  transform: rotate(-45deg);
  pointer-events: none;
}
.select-custom select {
  width: 120%;
}

.custom-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
}
.custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.custom-checkbox input:checked ~ span {
  background: #003049;
  color: #fff;
}
.custom-checkbox input:checked ~ span::before {
  opacity: 1;
  display: block;
}
.custom-checkbox span {
  display: flex;
  align-items: center;
}
.custom-checkbox span::before {
  content: "";
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 15px;
  height: 8px;
  display: block;
  transform: rotate(-40deg);
  margin-top: -5px;
  margin-left: -5px;
  margin-right: 8px;
  opacity: 0;
  display: none;
}

/* .custom-date {
  overflow: hidden;
  border: 1px solid #e0e4e8;
}
.custom-date input {
  width: 130%;
}
.custom-date svg {
  pointer-events: none;
} */

.card-lists div:last-child .card-list-inner {
  border: none;
}

/* Outseta */

.outseta-name {
  display: inline-block;
}

.outseta-name::before {
  content: ", ";
}

.outseta-name:empty {
  display: none;
}

@layer utilities {
  .overflow-unset {
    overflow: unset;
  }
}

.highlight-text {
  text-decoration: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(255, 255, 255, 0)),
    color-stop(50%, #fede84)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #fede84 50%);
}
